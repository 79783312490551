<template>
  <div class="magazine-wrapper">
    <Breadcrumb class="d-none d-sm-flex px-8" :items="breadcrumb" />
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
      :container="false"
    />
    <v-row class="px-lg-9 py-8">
      <v-col cols="12" md="6">
        <category-block
          :target="category"
          position="position2"
          class="category-block category-block-2"
        />
      </v-col>
      <v-col cols="12" md="6" class="px-5">
        <div
          class="d-flex flex-column flex-md-row pa-5 pa-lg-8 grey lighten3 rounded-lg"
        >
          <v-row class="justify-space-between" v-if="latestIssue">
            <div class="my-auto">
              <div class="d-flex flex-column">
                <h3 class="my-0 text-uppercase">
                  {{ $dayjs(latestIssue?.fromDate).format("MMMM YYYY") }}
                </h3>
                <h2>{{ latestIssue?.title }}</h2>
                <v-btn
                  :to="`/volantini/${latestIssue.leafletId}`"
                  large
                  class="pa-6 magazine-wrapper-discover elevation-0 rounded-pill"
                  color="primary"
                >
                  <span class="font-weight-normal">{{
                    $t("leaflet.rivista.discover")
                  }}</span>
                </v-btn>
              </div>
            </div>
            <div>
              <v-img
                class="mt-4 magazine-wrapper-cover rounded-md"
                contain
                :src="
                  `${latestIssue.baseLocation}${latestIssue.firstPagePreview}`
                "
              ></v-img>
            </div>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row class="px-sm-5 px-lg-9">
      <v-col cols="12">
        <LeafletMagazine />
      </v-col>
    </v-row>
  </div>
</template>
<style lang="scss">
.magazine-wrapper {
  &-cover {
    height: auto;
  }
  &-discover {
    max-width: fit-content;
  }
}

// @media #{map-get($display-breakpoints, 'md-and-up')} {
.magazine-wrapper-cover {
  width: 200px;
  max-width: 200px;
}
// }
</style>
<script>
import category from "~/mixins/category";
import leafletService from "@/commons/service/leafletService";
import LeafletMagazine from "../leaflet/LeafletMagazine.vue";
import Breadcrumb from "../navigation/Breadcrumb.vue";
export default {
  name: "magazine",
  mixins: [category],
  components: {
    Breadcrumb,
    LeafletMagazine
  },
  data() {
    return {
      magazines: null,
      magazinesLoading: false
    };
  },
  props: {},
  computed: {
    latestIssue() {
      return this.magazines && this.magazines[0];
    }
  },
  methods: {
    async fetchMagazines() {
      this.magazinesLoading = true;
      try {
        this.magazines = await leafletService.search({
          parent_leaflet_type_id: 2
        });

        if (this.magazines && this.magazines.length > 0) {
          this.magazines.sort((a, b) => {
            if (!a.fromDate) return -1;
            if (!b.fromDate) return 1;
            return new Date(b.fromDate) - new Date(a.fromDate);
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.magazinesLoading = false;
      }
    }
  },
  async created() {
    await this.fetchMagazines();
  }
};
</script>
